import React from 'react'
import ContactPage from 'templates/ContactPage'
import PropTypes from 'prop-types'

const Contact = ({ location }) => {
  console.log(location)
  return (
    <ContactPage
      location={location}
      pageContext={
        {
          title: 'Contactar',
          description: 'Envie uma mensagem à Caldeira Guesthouse. Se necessita de algum esclarecimento ou se nos pretende visitar não hesite em contactar.          '
        }
      }
    />
  )
}

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Contact
